var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{},[_c('div',[_c('v-data-table',{staticClass:"elevation-1 mb-10",attrs:{"headers":_vm.headers,"items":_vm.fields_centro_medico_usuario.json_centro_medico.json_centro_medico,"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.json_especialidades",fn:function(ref){
var item = ref.item;
return _vm._l((item.json_especialidades),function(item_esp,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item_esp.tx_especialidad)+" ")])})}},{key:"item.json_centro_medico",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.json_centro_medico.tx_razon_social)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Agregar Centro Médico ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"cm_esp.json_centro_medico","rules":_vm.fields_centro_medico_usuario.json_centro_medico.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.centrosmedicos,"loading":_vm.isLoading,"error-messages":errors,"hide-no-data":"","hide-selected":"","item-text":"tx_razon_social","item-value":"nu_id_centro_medico","label":_vm.fields_centro_medico_usuario.json_centro_medico.caracteristicas.label,"placeholder":_vm.fields_centro_medico_usuario.json_centro_medico.caracteristicas.placeholder,"prepend-icon":"","return-object":""},model:{value:(_vm.cm_esp.json_centro_medico),callback:function ($$v) {_vm.$set(_vm.cm_esp, "json_centro_medico", $$v)},expression:"cm_esp.json_centro_medico"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"cm_esp.json_especialidades","rules":_vm.fields_centro_medico_usuario.json_especialidades.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":_vm.fields_centro_medico_usuario.json_especialidades.caracteristicas.placeholder,"error-messages":errors,"items":_vm.fields_especialidad_usuario.json_especialidad.json_especialidad,"item-text":"tx_especialidad","item-value":"nu_id_especialidad","label":_vm.fields_centro_medico_usuario.json_especialidades.caracteristicas.label,"persistent-hint":"","multiple":"","return-object":""},model:{value:(_vm.cm_esp.json_especialidades),callback:function ($$v) {_vm.$set(_vm.cm_esp, "json_especialidades", $$v)},expression:"cm_esp.json_especialidades"}})]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Guardar")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" No hay información ")])]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }