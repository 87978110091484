 <template>
  <div>
      <div class="w-full">
        <UsuarioConfigHeader :titulo="titulo" /> 
      </div>
      <div class="w-full">
        <UsuarioConfigOpciones @guardar_usuario="return_usuario" :send_usuario="send_usuario"/>
      </div>
      <div class="w-full">
          <UsuarioConfigListar :usuarios="datos_usuario"></UsuarioConfigListar>
      </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UsuarioConfigHeader from "@/components/comunes/HeaderModulo.vue";
import UsuarioConfigOpciones from "@/views/seguridad/UsuarioConfigOpciones.vue";
import UsuarioConfigListar from "@/views/seguridad/UsuarioConfigListar.vue";

import {  SET_ERROR } from '@/store/mutations.type'
import { MUESTRA_USUARIO_USERNAME, MUESTRA_PERSONA } from '@/store/actions.type'

export default {
  name: 'UsuarioConfig',
  data () {
    return {
      titulo: 'Configurar usuario',
      datos_usuario: [],
      send_usuario: {
          datos_persona: {},
          datos_usuario: {},
          datos_especialidades: {},
          datos_centro_medico: {},
          
      },

    }
  },
  setup() {
  },  
  computed: {
      ...mapGetters(['currentUser']),
  },
  components: {
    UsuarioConfigHeader,
    UsuarioConfigOpciones,
    UsuarioConfigListar
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
    searchUsuarioConfig(param) {
      this.$store.dispatch(MUESTRA_USUARIO_USERNAME, {param: param}).then(response => {
          this.send_usuario.datos_usuario = JSON.parse(response.rest).items[0]
          console.log("this.send_usuario.datos_usuario searchUsuarioConfig: ",this.send_usuario.datos_usuario.nu_id_persona );
          this.send_usuario.datos_usuario.operacion = 'EDITAR'
          this.searchPersona({nu_id_persona: this.send_usuario.datos_usuario.nu_id_persona})
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    searchPersona(param) {
      this.$store.dispatch(MUESTRA_PERSONA, param).then(response => {
          this.send_usuario.datos_persona = JSON.parse(response.rest).items[0]
          this.send_usuario.datos_persona.operacion = 'EDITAR'

      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },       
    return_usuario(msg) {
      //this.buscarPersona('params')
      console.log("select_representante: ", msg );
       console.log("select_representante: ", msg.btn_guardar );
      //var guardar_usuario = JSON.parse(msg);

      
      if (msg.btn_guardar == 'true') {
        this.searchUsuarioConfig({})
      }
      //this.send_usuario = msg;
    },      
    return_consulta_medica(msg) {
        //this.buscarPersona('params')
        var guardar_usuario = msg;
        //this.select_usuario = JSON.parse(msg);
        console.log("return_consulta_medica guardar_usuario update: " , guardar_usuario);

        
        if (guardar_usuario.btn_guardar == 'true') {
            this.searchUsuarioConfig({})
            //this.fields.usuario = this.select_usuario.nu_id_representante
            console.log("Guarda ultima medica: " + guardar_usuario.btn_guardar);
        } else {
            console.log("Cancelar: " + guardar_usuario.btn_guardar);
        }
        this.dialogConsultaMedica = false
        //this.send_usuario = msg;
    },        

  },
  mounted () {
      /*this.$store.dispatch(MUESTRA_USUARIO_USERNAME, {param: this.currentUser.username}).then(response => {
      console.log("send_usuario.datos_usuario items: ", JSON.parse(response.rest).items[0] );

          this.send_usuario.datos_usuario = JSON.parse(response.rest).items[0]
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
*/
    this.searchUsuarioConfig({param: this.currentUser.username})

  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
