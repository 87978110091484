<template>
<div class="md:flex md:items-start">
  <div class="w-1/6">
      <ul class="
        nav nav-tabs nav-justified
        flex flex-col
        flex-wrap
        list-none
        border-b-0
        pl-0
        mb-4
      " id="tabs-tabJustify" role="tablist">
        <li class="nav-item flex-grow"  @click="handleClick('group_usuario')" role="presentation">
          <a href="#tabs-personaJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-x-0 border-t-0 border-b-2 border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
            active
          " id="tabs-persona-tabJustify"   data-bs-toggle="pill" data-bs-target="#tabs-personaJustify" role="tab"
            aria-controls="tabs-personaJustify" aria-selected="true">Datos del usuario</a>
        </li>
        <li class="nav-item flex-grow"  @click="handleClick('group_especialidades')" role="presentation">
          <a href="#tabs-especialidadesJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-x-0 border-t-0 border-b-2 border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-especialidades-tabJustify"   data-bs-toggle="pill" data-bs-target="#tabs-especialidadesJustify" role="tab"
            aria-controls="tabs-especialidadesJustify" aria-selected="true">Especialidades</a>
        </li>
        <li class="nav-item flex-grow"  @click="handleClick('group_centromedico')" role="presentation">
          <a href="#tabs-centromedicoJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-x-0 border-t-0 border-b-2 border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-centromedico-tabJustify"   data-bs-toggle="pill" data-bs-target="#tabs-centromedicoJustify" role="tab"
            aria-controls="tabs-centromedicoJustify" aria-selected="true">Centros médico</a>
        </li>
<!--        
        <li class="nav-item flex-grow"  @click="handleClick('group_horarios')" role="presentation">
          <a href="#tabs-horariosJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-x-0 border-t-0 border-b-2 border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-horarios-tabJustify"   data-bs-toggle="pill" data-bs-target="#tabs-horariosJustify" role="tab"
            aria-controls="tabs-horariosJustify" aria-selected="true">Horarios</a>
        </li>
-->
        <li class="nav-item flex-grow" @click="cerrar({})" role="presentation">
          <a href="#tabs-cerrarJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-x-0 border-t-0 border-b-2 border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-cerrar-tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-cerrarJustify" role="tab"
            aria-controls="tabs-cerrarJustify" aria-selected="false">
              Cerrar
            </a>
        </li>

      </ul>
    
  </div>
  <div class="tab-content w-full md:w-4/6 bg-gray-50 p-2 rounded-lg" id="tabs-tabContentJustify">
      <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
        <NotificacionInterna :mensaje="mensaje" />  
      </div>

      <div class="tab-pane fade active show"  id="tabs-personaJustify" role="tabpanel"
        aria-labelledby="tabs-persona-tabJustify">
          <div class=" border-2 rounded-md shadow-md">
            <PersonaRegistrar titulo="" @guardar_persona="return_persona" :send_persona="send_usuario.datos_persona"/>        
          </div>
      </div>
      <div class="tab-pane fade"  id="tabs-especialidadesJustify" role="tabpanel"
        aria-labelledby="tabs-especialidades-tabJustify">
          <div class=" border-2 rounded-md shadow-md">
            <UsuarioEspecialidadesRegistrar titulo="" @guardar_send_especialidades="return_especialidades" :send_especialidades="send_usuario.datos_especialidades"/>        
          </div>
      </div>
      <div class="tab-pane fade"  id="tabs-centromedicoJustify" role="tabpanel"
        aria-labelledby="tabs-centromedico-tabJustify">
          <div class=" border-2 rounded-md shadow-md">
            <UsuarioCentroMedicoRegistrar titulo="" @guardar_centro_medico_usuario="return_centro_medico_usuario" :send_centro_medico="send_usuario.datos_centro_medico"/>        
          </div>
      </div>
      <!--
      <div class="tab-pane fade active show"  id="tabs-horariosJustify" role="tabpanel"
        aria-labelledby="tabs-horarios-tabJustify">
          <div class=" border-2 rounded-md shadow-md">
            <PersonaRegistrar titulo="" @guardar_horarios="return_horarios" :send_horarios="send_usuario.datos_horarios"/>        
          </div>
      </div>
      -->

  </div>
  <div class="text-sm w-1/6">
      
      <div>
        <div class="accordion accordion-flush text-sm" id="accordionFlushExample">
          <div v-if="send_usuario.datos_persona.tx_usuario"  class="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
            <h2 class="accordion-header mb-0" id="flush-headingOne">
              <button class="accordion-button
            relative
            flex
            items-center
            py-4
            px-5
            text-sm 
            text-gray-800 
            text-left
            bg-white
            border-0
            rounded-none
            transition
            focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                aria-expanded="false" aria-controls="flush-collapseOne">
                Consultas medicas anteriores
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body py-4 px-5">
                  <div class="text-xs" v-for="(item, index) in consultasmedica" :key="index">      
                    {{ item.fe_consulta }}
                    {{ item.tx_motivo }}
                  </div>
              </div>
            </div>
          </div>
          <div class="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
            <h2 class="accordion-header mb-0" id="flush-headingTwo">
              <button class="accordion-button
            collapsed
            relative
            flex
            items-center
            w-full
            py-4
            rounded-none
            px-5
            text-sm text-gray-800 text-left
            bg-white
            border-0
            transition
            focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                aria-expanded="false" aria-controls="flush-collapseTwo">
                Agenda
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to
                demonstrate
                the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this
                being
                filled with some actual content.</div>
            </div>
          </div>
          <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
            <h2 class="accordion-header mb-0" id="flush-headingThree">
              <button class="accordion-button
            collapsed
            relative
            flex
            items-center
            w-full
            py-4
            px-5
            text-sm text-gray-800 text-left
            bg-white
            border-0
            rounded-none
            transition
            focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                aria-expanded="false" aria-controls="flush-collapseThree">
                Seguridad
              </button>
            </h2>
            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body py-4 px-5">
                <div class="border-t-2" v-for="(item, index) in locales.grupos" :key="index">      
                  {{ index }}
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
            <h2 class="accordion-header mb-0" id="flush-headingPlantilla">
              <button class="accordion-button
            collapsed
            relative
            flex
            items-center
            w-full
            py-4
            px-5
            text-base text-gray-800 text-left
            bg-white
            border-0
            rounded-none
            transition
            focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsePlantilla"
                aria-expanded="false" aria-controls="flush-collapsePlantilla">
                Plantilla
              </button>
            </h2>
            <div id="flush-collapsePlantilla" class="accordion-collapse collapse" aria-labelledby="flush-headingPlantilla"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to
                demonstrate
                the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting
                happening here in terms of content, but just filling up the space to make it look, at least at first
                glance,
                a bit more representative of how this would look in a real-world application.</div>
            </div>
          </div>          
        </div>   
        <div v-if="send_usuario.datos_usuario.tx_usuario" class="py-4 px-5 bg-white  border-gray-200">
          <span>Última consulta</span>
            <div>
              <div>
              </div>
              <div>
                <div class="border-t-2" v-for="(item, index) in locales.grupos" :key="index">      
                  {{ locales.grupos[index].caracteristicas.label  }}
                  {{ locales.grupos[index][index]  }}
                </div>              
            </div>
            </div>

        </div>
      </div>
  </div>
</div>
</template>

<script>
import {  UPDATE_USUARIO, CREATE_CENTRO_MEDICO_USUARIO } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import { mapGetters } from 'vuex'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import PersonaRegistrar from "@/components/persona/PersonaRegistrar";
import UsuarioEspecialidadesRegistrar from "@/components/seguridad/UsuarioEspecialidadesRegistrar";
import UsuarioCentroMedicoRegistrar from "@/components/seguridad/UsuarioCentroMedicoRegistrar";

const moment = require('moment');

  export default {
    name: 'UsuarioConfigActualizar',
    data() {
        return {
          consultasmedica: [],
          tabs: {
            group_usuario:{
              active: '',
              class: ''
            },
            group_consulta_medica:{
              active: 'active',
              class: 'show active'
            },
          },
          fields_usuario_temp: '',
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
          locales:{
            grupos:'',
            fields_usuario: ''
          },
          isHistoria: false,
          pestana: {
            collapseDatosBasico: '',
            collapseHistoriaClinica: '',      
            collapseMotivo: '',
            collapseEnfermedadActual: '',
            collapseAntecedentesEnfermedadActual: '',
            collapseAntecedentesPersonales: '',
            collapseAntecedentesFamiliares: '',
            collapseExamenFisico: ''            
          }
        }
    },    
    computed: {
        ...mapGetters(['fields_usuario']),
        hoy() {
            const today = new Date();
            var fecha2 = moment(today);
            return fecha2.format('DD-MM-YYYY');
        },            

    },    
    props: {
        send_usuario: Object,
        titulo: String
    },
    components: {
      PersonaRegistrar,
      UsuarioCentroMedicoRegistrar,
      UsuarioEspecialidadesRegistrar,
      NotificacionInterna,
    },
    methods: {
        handleClick(e) {
          console.log('blur', e)
          this.locales.grupos = this.fields_usuario.[e]
        },      
        cerrar(msg) {
          this.$emit("guardar_usuario", msg);
        },    
        ocultar_pestana() {
          for (var property in this.pestana) {
                this.pestana[property] = 'hide'
            }
        },
        mostrar_pestana(obj) {
                this.pestana[obj] = 'show'
        },
        // Paso #1        
        return_persona(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
              console.log('guardar Actualizar: ', guardar)

            const formData = new FormData()

            formData.append('persona', JSON.stringify(guardar))

            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

              formData.append('oper', 'EDITAR')
              
              this.$store.dispatch(UPDATE_USUARIO, formData).then(datos => {
                guardar.btn_guardar = 'true';
                this.fields_usuario.group_usuario.nu_id_persona.nu_id_persona = datos.nu_id_persona
                this.fields_usuario.group_usuario.tx_usuario.tx_usuario = datos.tx_usuario
                this.fields_usuario.group_usuario.fe_apertura.fe_apertura = this.hoy
                setTimeout(() => {
                     // this.isLoading = false
                      console.log('message emit from child component', datos)
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Paciente ' + datos.tx_nombre_completo + ' actualizado satisfactoriamente!'
                      });      

                  },3000)                  
                  this.$refs.tabs_consultamedica_tabJustify.click();

                
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })

// hasta     

            console.log("Datos a enviar: " , guardar);
            console.log("Datos a enviar send_usuario 01: " , this.send_usuario);


            //this.select_persona = JSON.parse(msg);[


        },    
        return_especialidades(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
              console.log('guardar Actualizar: ', guardar)

            const formData = new FormData()

            formData.append('persona', JSON.stringify(guardar))

            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

              formData.append('oper', 'EDITAR')
              
              this.$store.dispatch(UPDATE_USUARIO, formData).then(datos => {
                guardar.btn_guardar = 'true';
                this.fields_usuario.group_usuario.nu_id_persona.nu_id_persona = datos.nu_id_persona
                this.fields_usuario.group_usuario.tx_usuario.tx_usuario = datos.tx_usuario
                this.fields_usuario.group_usuario.fe_apertura.fe_apertura = this.hoy
                setTimeout(() => {
                     // this.isLoading = false
                      console.log('message emit from child component', datos)
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Paciente ' + datos.tx_nombre_completo + ' actualizado satisfactoriamente!'
                      });      

                  },3000)                  
                  this.$refs.tabs_consultamedica_tabJustify.click();

                
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })

// hasta     

            console.log("Datos a enviar: " , guardar);
            console.log("Datos a enviar send_usuario 01: " , this.send_usuario);


            //this.select_persona = JSON.parse(msg);[


        },    
        return_centro_medico_usuario(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
              console.log('guardar Actualizar: ', guardar)

            const formData = new FormData()

            formData.append('centro_medico_usuario', JSON.stringify(guardar))

            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

              formData.append('oper', 'EDITAR')
              
              this.$store.dispatch(CREATE_CENTRO_MEDICO_USUARIO, formData).then(datos => {
                guardar.btn_guardar = 'true';
                this.fields_usuario.group_usuario.nu_id_persona.nu_id_persona = datos.nu_id_persona
                this.fields_usuario.group_usuario.tx_usuario.tx_usuario = datos.tx_usuario
                this.fields_usuario.group_usuario.fe_apertura.fe_apertura = this.hoy
                setTimeout(() => {
                     // this.isLoading = false
                      console.log('message emit from child component', datos)
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Paciente ' + datos.tx_nombre_completo + ' actualizado satisfactoriamente!'
                      });      

                  },3000)                  
                  this.$refs.tabs_consultamedica_tabJustify.click();

                
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })

// hasta     

            console.log("Datos a enviar: " , guardar);
            console.log("Datos a enviar send_usuario 01: " , this.send_usuario);


            //this.select_persona = JSON.parse(msg);[


        },   
        return_horarios(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
              console.log('guardar Actualizar: ', guardar)

            const formData = new FormData()

            formData.append('persona', JSON.stringify(guardar))

            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

              formData.append('oper', 'EDITAR')
              
              this.$store.dispatch(UPDATE_USUARIO, formData).then(datos => {
                guardar.btn_guardar = 'true';
                this.fields_usuario.group_usuario.nu_id_persona.nu_id_persona = datos.nu_id_persona
                this.fields_usuario.group_usuario.tx_usuario.tx_usuario = datos.tx_usuario
                this.fields_usuario.group_usuario.fe_apertura.fe_apertura = this.hoy
                setTimeout(() => {
                     // this.isLoading = false
                      console.log('message emit from child component', datos)
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Paciente ' + datos.tx_nombre_completo + ' actualizado satisfactoriamente!'
                      });      

                  },3000)                  
                  this.$refs.tabs_consultamedica_tabJustify.click();

                
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })

// hasta     

            console.log("Datos a enviar: " , guardar);
            console.log("Datos a enviar send_usuario 01: " , this.send_usuario);


            //this.select_persona = JSON.parse(msg);[


        },   


    },
    mounted() {
    },         

    
  }
</script>

<style lang="scss" scoped>

</style>