<template>
    <div class="text-skin-base w-full">
                <div class="md:flex  justify-end md:justify-start w-full">                  
                    <div class="my-auto p-2 md:pl-10 w-full md:w-1/5">

                        <button @click="configurar_usuario()" class="w-full font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-4 mt-1 m-1">
                            Configurar usuario
                        </button>                          
                    </div>
<!--                            @change="verificar_frecuencia()"                        
-->        
                </div>


        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogUsuarioConfig" :value="true" @input="dialogUsuarioConfig = false"                   
            >
            <v-card>
              <v-container>
                <div>
                    <HistoriaHeader :titulo="titulo" /> 
                </div>


                <div  class="md:p-2 flex justify-between text-skin-base bg-gray-50">
                    <div class="p-2 text-sm md:text-lg">                        
                        Nombre usuario: <span><strong>{{ fields_persona.tx_apellidos.tx_apellidos}} {{ fields_persona.tx_nombres.tx_nombres}}</strong>  </span>                        
                    </div>        
<!--                    <div class="p-2 text-sm md:text-lg">                        
                        Fecha de creación: <span><strong>{{ fields_usuario.group_usuario.fe_apertura.fe_apertura }}</strong>  </span>
                    </div>        
-->                    
                    <div class="p-2 text-sm md:text-lg">                        
                        Usuario: <span><strong>{{ currentUser.username }}</strong>  </span>
                    </div>        

                </div>                

                <div class="w-full mx-auto my-auto">
                    <UsuarioConfigActualizar titulo="Registrar historia" @guardar_usuario="return_usuario" :send_usuario="send_usuario"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
//import { READ_PACIENTE } from '@/store/actions.type'
//import { SET_ERROR } from '@/store/mutations.type'
import UsuarioConfigActualizar from "@/components/seguridad/UsuarioConfigActualizar.vue";
import HistoriaHeader from "@/components/comunes/HeaderModulo.vue";

export default {
    name: 'UsuarioConfigOpciones',
    data() {
        return {
            dialogUsuarioConfig: false,
            buscarPaciente: false, 
            send_motivo: {},
            datos_usuario: [],
            isLoading: false
        }
    },
    props: {
        titulo: String,
        send_usuario: Object,

    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
    
        configurar_usuario () {
            this.dialogUsuarioConfig = true
            this.send_usuario.operacion = 'EDITAR'
            //this.send_usuario.datos_usuario = this.json_usuario
            // console.log(value) // someValue
        },
        return_usuario(msg) {
            //this.buscarPersona('params')
            //var guardar = JSON.parse(msg);
            var guardar = msg;
            //this.select_usuario = JSON.parse(msg);
            console.log("return_usuario guardar_usuario: " , guardar);

            
            this.$emit("guardar_usuario", guardar);
            this.dialogUsuarioConfig = false
            //this.send_usuario = msg;
        },    

    },
    computed: {
        ...mapGetters(['fields_usuario','fields_persona','currentUser']),
    },
    components: {

        UsuarioConfigActualizar,
        HistoriaHeader
    },
    mounted() {
        
        //this.searchPaciente()
    },     
}
</script>