<template>
  <div class="">
        <div class="">
            <div>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">

                        <validation-provider
                            v-slot="{ errors }"
                            name="fields_especialidad_usuario.json_especialidad.json_especialidad"
                            :rules=fields_especialidad_usuario.json_especialidad.caracteristicas.required
                        >
                            <v-autocomplete
                            v-model="fields_especialidad_usuario.json_especialidad.json_especialidad"
                            :items="especialidades"
                            :error-messages="errors"
                            chips
                            color="blue-grey lighten-2"
                            :label=fields_especialidad_usuario.json_especialidad.caracteristicas.label
                            item-text="tx_especialidad"
                            item-value="nu_id_especialidad"
                            multiple
                            v-bind="attrs"
                            v-on="on"
                            return-object
                            >
                            <template v-slot:selection="data">
                                <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="remove(data.item)"
                                >
                                {{ data.item.tx_especialidad }}
                                </v-chip>
                            </template>

                            </v-autocomplete>


                        </validation-provider>
                  </template>
                  <span>{{fields_especialidad_usuario.json_especialidad.caracteristicas.tooltip  }} </span>
                </v-tooltip>                

            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  READ_ESPECIALIDAD } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'UsuarioEspecialidadesDatosBasicos',
  data () {
    return {
        especialidades: [],
        isLoading: false,
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_especialidad_usuario']),
  },
  props: {
    send_especialidades: Object
  },
  components: {

  },    
  methods: {
    searchEspecialidad() {
      this.$store.dispatch(READ_ESPECIALIDAD, {}).then(response => {
          this.especialidades = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    remove (item) {
      var index = this.fields_especialidad_usuario.json_especialidad.json_especialidad.findIndex(i => i.nu_id_especialidad === item.nu_id_especialidad);
      console.log("Valor a remover: ", index)
      if (index >= 0) this.fields_especialidad_usuario.json_especialidad.json_especialidad.splice(index, 1)
    },    

  },
  
  mounted() {
    this.searchEspecialidad();
  },
    
}
</script>