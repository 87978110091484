<template>
    <div>
        <ValidationObserver ref="datos_basicos">
        <div  v-if="!isHiddenRepresentante">
                <v-btn
                color="primary"
                class="w-full"
                @click="tomar_inf_contacto()"
                >
                Tomar los datos del Representante
                </v-btn>                        
        </div> 
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">

        <validation-provider
            v-slot="{ errors }"
            name="fields_persona.group_datos_contacto.tx_telefono_local.tx_telefono_local"
            rules=""
        >
            <v-text-field
            v-model="fields_persona.group_datos_contacto.tx_telefono_local.tx_telefono_local"
            class="input-group--focused mb-10 text-lg"
            v-mask="'(###) ###-####'"                            
            :error-messages="errors"
            label="Teléfono local"
            v-bind="attrs"
            v-on="on"
            :disabled=!fields_persona.isEditing
            outlined
            ></v-text-field>
        </validation-provider>  
        </template>
        <span>{{ fields_persona.group_datos_contacto.tx_telefono_local.caracteristicas.tooltip  }} </span>
        </v-tooltip>                
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">

        <validation-provider
            v-slot="{ errors }"
            name="fields_persona.group_datos_contacto.tx_telefono_celular.tx_telefono_celular"
        >
            <v-text-field
            v-model="fields_persona.group_datos_contacto.tx_telefono_celular.tx_telefono_celular"
            class="input-group--focused mb-10 text-lg"
            v-mask="'(###) ###-####'"
            :error-messages="errors"
            label="Teléfono celular"
            v-bind="attrs"
            v-on="on"
            :disabled=!fields_persona.isEditing
            outlined
            ></v-text-field>
        </validation-provider>  
        </template>
        <span>{{ fields_persona.group_datos_contacto.tx_telefono_celular.caracteristicas.tooltip  }} </span>
        </v-tooltip>                
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">

        <validation-provider
            v-slot="{ errors }"
            name="fields_persona.group_datos_contacto.tx_correo.tx_correo"
            rules="email"
        >
            <v-text-field
                v-model="fields_persona.group_datos_contacto.tx_correo.tx_correo"
                class="input-group--focused mb-10 text-lg"
                :error-messages="errors"
                label="Correo Electrónico"   
                v-bind="attrs"
                v-on="on"
                :disabled=!fields_persona.isEditing
                outlined
            ></v-text-field>
        </validation-provider>
        </template>
        <span>{{ fields_persona.group_datos_contacto.tx_correo.caracteristicas.tooltip  }} </span>
        </v-tooltip>                
                <!-- Inicio Boton de Navegacion -->
            <div class="flex justify-end">

                <div>
                        <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                    Cancelar
                        </button>                          
                        <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiStopCircleOutline }}
                                        </v-icon>
                        </button>                          
                    
                </div>
                <div>

                        <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                            Procesando...
                        </button>                                                        
                        <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                            Guardar
                        </button>                                                        

                        <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                        </button>                                                        
                

                        <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiContentSave }}
                                        </v-icon>
                        </button>                          
                </div>
                </div>
            <!-- Fin Boton de Navegacion -->    

          </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { reactive  } from "@vue/composition-api"

import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached,
  mdiStopCircleOutline,
  mdiContentSave
} from '@mdi/js'

    export default {
        name: 'PersonaDatosContacto',
        data () {
            return {
                isLoading: false,
                select_representante:'',
                isHiddenRepresentante: true,
            }
        },
        setup() {
            const icons = reactive({
                mdiClose,
                mdiAccount,
                mdiAlarmMultiple,
                mdiAlarmPlus,
                mdiPencil,
                mdiCalendar,
                mdiAccountHeart,
                mdiNeedle,
                mdiRadiologyBoxOutline,
                mdiCached,
                mdiDelete,
                mdiStopCircleOutline,
                mdiContentSave
            });  


            return {
            icons
            }
        },  
        computed: {
            ...mapGetters(['fields_persona']),
        },
        props: {
            send_persona: Object
        },
        methods: {
            async guardar () {
                const isValid = await this.$refs.datos_basicos.validate()
                if (!isValid) {
                    return true
                }                                                                
                this.$emit("guardar_datos_contacto", JSON.stringify(this.fields_persona.group_datos_contacto));
            },              
            async cancelar () {
                this.$notify({
                group: 'error',
                type: 'Info',
                title: 'Mensaje importante',
                classes: 'w-full text-lg',
                text: 'Operación cancelada!'
                });      
            //this.loading4 = true 
                this.$emit("guardar_enfermedad_actual", JSON.stringify(this.fields_historia_clinica));

            this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

            }, 



        },
        mounted() {
            if (this.send_persona.operacion === 'EDITAR') {
                    console.log('prop: ', this.fields_persona)
                for (var prop in this.fields_persona.group_datos_contacto) {
                    this.fields_persona.group_datos_contacto[prop][prop] = this.send_persona.institucion[prop]
                }                
                //this.isHiddenTieneCedula = this.fields_persona.group_datos_ubigeo.bo_posee_cedula.bo_posee_cedula
            }
        }

        
        
    }
    
</script>

<style lang="sass" scoped>

</style>