var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-4 m-2 text-lg text-skin-base bg-skin-fill rounded-lg"},[_c('v-text-field',{attrs:{"clearable":"","prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Buscar paciente / historia"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.historias_clinica,"items-per-page":5,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.registrar_consulta(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiBookPlusMultiple)+" ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}},{key:"item.tx_nombres",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.tx_nombres))])])]}},{key:"item.tx_apellidos",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.tx_apellidos))])])]}},{key:"item.nu_sexo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(item.nu_sexo === 1)?_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v("Femenino")]):_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v("Masculino")])])]}}],null,true)})],1),(_vm.dialogPaciente)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","value":true},on:{"input":function($event){_vm.dialogPaciente = false}}},[_c('v-card',[_c('v-container',[_c('div',{staticClass:"w-full md:w-1/2 mx-auto my-auto"},[_c('PacienteRegistrar',{attrs:{"titulo":"Editar datos del paciente","send_paciente":_vm.send_paciente},on:{"guardar_paciente":_vm.return_paciente}})],1)])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }