<template>
<div >
    <div class="p-2 border-2  rounded-md">
        <div>
            <UsuarioCentroMedicoHeader :titulo="titulo "  />        
        </div>
      <div>
                <ValidationObserver ref="datos_UsuarioCentroMedicoDatosBasicos">

                    <UsuarioCentroMedicoDatosBasicos   :send_centro_medico="send_centro_medico">
                    </UsuarioCentroMedicoDatosBasicos>
                    <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-between">
                      <div class="">
                          <div class="flex justify-start">
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          </div>    


                      </div>


                      <div class="">
                          <div class="flex justify-start">

                                <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                  Procesando...
                                </button>                                                        
                                <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                  Guardar
                                </button>                                                        

                                <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                </button>                                                        
                      

                                <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiContentSave }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>
                    </div>
                    <!-- Fin Boton de Navegacion -->   
                </ValidationObserver>

          
      </div>       
    </div> 

</div>
</template>
<script>
import UsuarioCentroMedicoDatosBasicos from "@/components/seguridad/UsuarioCentroMedicoDatosBasicos.vue";
import UsuarioCentroMedicoHeader from "@/components/comunes/HeaderModulo.vue";
import { mapGetters } from 'vuex'
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus,
  mdiArrowRightBoldOutline,
  mdiArrowLeftBoldOutline,
  mdiStopCircleOutline,
  mdiContentSave,
  mdiLoading

} from '@mdi/js';
import { reactive  } from "@vue/composition-api"

export default {
    name: 'UsuarioCentroMedicoRegistrar',
    data() {
        return {
            isLoading: false,
 //           titulo: "Programar "
        }
    },
    computed: {
        ...mapGetters(['fields_centro_medico_usuario']),
    },
    props: {
        send_centro_medico: Object,
        titulo: String
    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus,
            mdiArrowRightBoldOutline,
            mdiArrowLeftBoldOutline,
            mdiStopCircleOutline,
            mdiContentSave,
            mdiLoading
        });  


        return {
        icons
        }
    },
    components: {
      UsuarioCentroMedicoDatosBasicos,
      UsuarioCentroMedicoHeader
    },
    methods: {    

        async guardar () {
            const isValid = await this.$refs.datos_UsuarioCentroMedicoDatosBasicos.validate()
            console.log('Redes Sociales Antes: ', isValid, this.$refs.datos_UsuarioCentroMedicoDatosBasicos)
            if (!isValid) {
                return true
            }
            console.log('Redes Sociales dESPUES: ', isValid, this.$refs.datos_UsuarioCentroMedicoDatosBasicos)

            // this.isLoading = true
            this.$emit("guardar_centro_medico_usuario", JSON.stringify(this.fields_centro_medico_usuario));

            
        // desde

        // hasta
            this.e1 =  1


        }, 
    },        
    mounted() {

    },         

  
}
</script>