<template>
  <div class="">
        <div class="">
            <div>
                      <div>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">

                          <validation-provider
                            v-slot="{ errors }"
                            name="fields_persona.group_datos_basico.tx_apellidos.tx_apellidos"
                            rules="required"
                          >
                            <v-text-field
                              v-model="fields_persona.group_datos_basico.tx_apellidos.tx_apellidos"
                              outlined
                              class="input-group--focused text-lg pt-2"
                              :error-messages="errors"
                              :label=fields_persona.group_datos_basico.tx_apellidos.caracteristicas.label
                              v-bind="attrs"
                              v-on="on"
                              :disabled=!fields_persona.isEditing                            
                            ></v-text-field>
                          </validation-provider>     
                            </template>
                            <span>{{fields_persona.group_datos_basico.tx_apellidos.caracteristicas.tooltip  }} </span>
                          </v-tooltip>                
                      </div>
                      <div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">


                        <validation-provider
                            v-slot="{ errors }"
                            name="fields_persona.group_datos_basico.tx_nombres.tx_nombres"
                            rules="required"
                          >
                              <v-text-field
                                v-model="fields_persona.group_datos_basico.tx_nombres.tx_nombres"
                                class="input-group--focused  text-lg"
                                :error-messages="errors"
                                :label=fields_persona.group_datos_basico.tx_nombres.caracteristicas.label
                                v-bind="attrs"
                                v-on="on"
                                :disabled=!fields_persona.isEditing                            
                                outlined
                                ></v-text-field>
                          </validation-provider>  
                          </template>
                          <span>{{ fields_persona.group_datos_basico.tx_nombres.caracteristicas.tooltip  }} </span>
                        </v-tooltip>                
                      </div>
                      <div>
                          <validation-provider
                            v-slot="{ errors }"
                            name="fields_persona.group_datos_basico.fe_nacimiento.fe_nacimiento"
                            :rules=fields_persona.group_datos_basico.fe_nacimiento.caracteristicas.required
                          >
                              <v-dialog
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="fields_persona.group_datos_basico.fe_nacimiento.fe_nacimiento"
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="fields_persona.group_datos_basico.fe_nacimiento.fe_nacimiento"
                                    :label=fields_persona.group_datos_basico.fe_nacimiento.caracteristicas.label
                                    :error-messages="errors"
                                    :prepend-icon=icons.mdiCalendar
                                    v-bind="attrs"
                                    v-on="on"
                                  outlined
                                  :disabled=!fields_persona.isEditing                            
                                  @blur="calcular_edad()"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="fields_persona.group_datos_basico.fe_nacimiento.fe_nacimiento"
                                  :max='fecha_reg_max'
                                  :date-format="date => new Date(date).toDateString()" 
                                  :formatted-value.sync="fields_persona.group_datos_basico.fe_nacimiento.fe_nacimiento"
                                  locale="es"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="modal = false"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialog.save(fields_persona.group_datos_basico.fe_nacimiento.fe_nacimiento)"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-date-picker>
                              </v-dialog>
                          </validation-provider>                                     
                      </div>
                      <div>         
                        <validation-provider
                          v-slot="{ errors }"
                          name="fields_persona.group_datos_basico.nu_edad.nu_edad"
                          :rules=fields_persona.group_datos_basico.nu_edad.caracteristicas.required
                        >
                          <v-text-field
                            v-model="fields_persona.group_datos_basico.nu_edad.nu_edad"
                            class="input-group--focused text-lg"
                            :error-messages="errors"
                            :label=fields_persona.group_datos_basico.nu_edad.caracteristicas.label 
                            maxlength="10"
                            outlined
                            :disabled='true'                            
                          >
                          </v-text-field>

                        </validation-provider>   
                      </div>
                      <div v-if="!isHiddenTieneCedula" class="pb-6">
                          <validation-provider
                            v-slot="{ errors }"                                        
                            name="fields_persona.group_datos_basico.bo_posee_cedula.bo_posee_cedula"
                            rules="required"                          
                          >
                          <div class="flex justify-center">
                            <span>Posee cédula de identidad?</span>
                            <div class="flex justify-start">
                              <div class="pl-2 pr-2">
                                <input @change="verificar_bo_posee_cedula()"    v-model="fields_persona.group_datos_basico.bo_posee_cedula.bo_posee_cedula"  value="true" :error-messages="errors" class=" rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio">
                                <label class="form-check-label inline-block text-gray-800" for="flexRadioDefault1">
                                  Si
                                </label>
                              </div>
                              <div class=" pl-2 pr-2">
                                <input @change="verificar_bo_posee_cedula()"  v-model="fields_persona.group_datos_basico.bo_posee_cedula.bo_posee_cedula" value="false" :error-messages="errors" class="  rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" >
                                <label class="form-check-label inline-block text-gray-800" for="flexRadioDefault2">
                                  No
                                </label>
                              </div>
                            </div>
                          </div>              
                          </validation-provider>
                      </div>  
                      <div v-if="isHiddenRepresentante">            
                          <validation-provider
                            v-slot="{ errors }"
                            name="fields_persona.group_datos_basico.tx_nacionalidad.tx_nacionalidad"
                            :rules=fields_persona.group_datos_basico.tx_nacionalidad.caracteristicas.required
                          >
                                  <v-select
                                    v-model="fields_persona.group_datos_basico.tx_nacionalidad.tx_nacionalidad"
                                    :items="d_nacionalidad"
                                    class="input-group--focused text-lg"
                                    :placeholder=fields_persona.group_datos_basico.tx_nacionalidad.caracteristicas.placeholder
                                    :error-messages="errors"
                                    :label=fields_persona.group_datos_basico.tx_nacionalidad.caracteristicas.label
                                    outlined
                                    :disabled=!fields_persona.isEditing
                                  ></v-select>                      

                          </validation-provider>                    
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="fields_persona.group_datos_basico.tx_documento_identidad.tx_documento_identidad"
                                    :rules=fields_persona.group_datos_basico.tx_documento_identidad.caracteristicas.required
                                  >

                                    <v-text-field
                                      v-model="fields_persona.group_datos_basico.tx_documento_identidad.tx_documento_identidad"
                                      class="input-group--focused text-lg"
                                      :error-messages="errors"
                                      :label=fields_persona.group_datos_basico.tx_documento_identidad.caracteristicas.label 
                                      v-bind="attrs"
                                      v-on="on"
                                      outlined
                                      maxlength="10"
                                      :disabled=!fields_persona.isEditing                            
                                    >
                                    </v-text-field>
                                  </validation-provider>                    
                              </template>
                              <span>{{fields_persona.group_datos_basico.tx_documento_identidad.caracteristicas.tooltip  }} </span>
                            </v-tooltip>                
                      </div>

                      <div>         
                        <validation-provider
                          v-slot="{ errors }"
                          name="fields_persona.group_datos_basico.nu_sexo.nu_sexo"
                          :rules=fields_persona.group_datos_basico.nu_sexo.caracteristicas.required
                        >
                            <v-select
                              v-model="fields_persona.group_datos_basico.nu_sexo.nu_sexo"
                              :items="d_sexo"
                              class="input-group--focused text-lg pt-4"
                              :error-messages="errors"
                              :label=fields_persona.group_datos_basico.nu_sexo.caracteristicas.label
                              outlined
                              :disabled=!fields_persona.isEditing                            
                            ></v-select>                      
                        </validation-provider>   
                      </div>
                      <div>         
                        <validation-provider
                          v-slot="{ errors }"
                          name="fields_persona.group_datos_basico.nu_estado_civil.nu_estado_civil"
                          :rules=fields_persona.group_datos_basico.nu_estado_civil.caracteristicas.required
                        >
                            <v-select
                              v-model="fields_persona.group_datos_basico.nu_estado_civil.nu_estado_civil"
                              :items="d_edo_civil"
                              class="input-group--focused text-lg pt-4"
                              :error-messages="errors"
                              :label=fields_persona.group_datos_basico.nu_estado_civil.caracteristicas.label
                              outlined
                              :disabled=!fields_persona.isEditing                            
                            ></v-select>                      
                        </validation-provider>   
                      </div>
                      <div>         
                        <validation-provider
                          v-slot="{ errors }"
                          name="fields_persona.group_datos_basico.nu_nivel_educativo.nu_nivel_educativo"
                          :rules=fields_persona.group_datos_basico.nu_nivel_educativo.caracteristicas.required
                        >
                            <v-select
                              v-model="fields_persona.group_datos_basico.nu_nivel_educativo.nu_nivel_educativo"
                              :items="d_nivel_educativo"
                              class="input-group--focused text-lg pt-4"
                              :error-messages="errors"
                              :label=fields_persona.group_datos_basico.nu_nivel_educativo.caracteristicas.label
                              outlined
                              :disabled=!fields_persona.isEditing                            
                            ></v-select>                      
                        </validation-provider>   
                      </div>     
                      <div>         
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">

                            <validation-provider
                              v-slot="{ errors }"
                              name="fields_persona.group_datos_basico.tx_profesion.tx_profesion"
                              :rules=fields_persona.group_datos_basico.tx_profesion.caracteristicas.required
                            >
                              <v-text-field
                                v-model="fields_persona.group_datos_basico.tx_profesion.tx_profesion"
                                class="input-group--focused text-lg"
                                :error-messages="errors"
                                :label=fields_persona.group_datos_basico.tx_profesion.caracteristicas.label 
                                v-bind="attrs"
                                v-on="on"
                                maxlength="10"
                                :disabled=!fields_persona.isEditing                            
                                outlined
                              >
                              </v-text-field>

                            </validation-provider>   
                          </template>
                          <span>{{fields_persona.group_datos_basico.tx_profesion.caracteristicas.tooltip  }} </span>
                        </v-tooltip>                

                      </div>
                      <div>         
                        <validation-provider
                          v-slot="{ errors }"
                          name="fields_persona.group_datos_basico.nu_mano_dominante.nu_mano_dominante"
                          :rules=fields_persona.group_datos_basico.nu_mano_dominante.caracteristicas.required
                        >
                            <v-select
                              v-model="fields_persona.group_datos_basico.nu_mano_dominante.nu_mano_dominante"
                              :items="d_mano_dominante"
                              class="input-group--focused text-lg pt-4"
                              :error-messages="errors"
                              :label=fields_persona.group_datos_basico.nu_mano_dominante.caracteristicas.label
                              outlined
                              :disabled=!fields_persona.isEditing                            
                            ></v-select>                      
                        </validation-provider>   
                      </div>
            </div>

        </div>                  


                    <div class="flex justify-between mb-4" v-if="!isHiddenRepresentante">
                        <div class="w-full" >
                            <validation-provider
                              v-slot="{ errors }"                                        
                              name="fields_persona.group_datos_basico.select_representante"
                              rules="required"                          
                            >
                              <v-autocomplete
                                v-model="fields_persona.group_datos_basico.select_representante"
                                :items="itemsRepresentante"
                                :loading="isLoading"
                                :error-messages="errors"
                                :search-input.sync="search"
                                hide-no-data
                                hide-selected
                                item-text="tx_nombre_completo"
                                item-value="nu_id_persona"                                
                                label="Representante"
                                placeholder="Comience su busqueda"
                                prepend-icon=""
                                outlined
                                return-object
                              ></v-autocomplete>                        
                            </validation-provider>
                        </div>
                        <div>
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="indigo"
                              @click="dialogRepresentante = !dialogRepresentante "
                            >
                              <v-icon dark>
                                mdi-plus
                              </v-icon>
                            </v-btn>
                        </div>
                    </div>
                <!-- Inicio Boton de Navegacion -->
            <div class="flex justify-end">

                <div>
                        <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                    Cancelar
                        </button>                          
                        <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiStopCircleOutline }}
                                        </v-icon>
                        </button>                          
                    
                </div>
                <div>

                        <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                            Procesando...
                        </button>                                                        
                        <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                            Guardar
                        </button>                                                        

                        <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                        </button>                                                        
                

                        <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiContentSave }}
                                        </v-icon>
                        </button>                          
                </div>
                </div>
            <!-- Fin Boton de Navegacion -->    

          
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  MUESTRA_PERSONA  } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import "@/common/rules.js";
const moment = require('moment');
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'PersonaDatosBasicos',
  data () {
    return {
        isHiddenTieneCedula: true,
        modal: false,                 
        isHiddenRepresentante: true,
        codRepresentante: [],
        search:null,
        isLoading: false,
        edad: { cantidad: 0, medida: 'año(s)'},
        d_nacionalidad: [
            { value: 'V', text: 'Venezolana' },
            { value: 'E', text: 'Extranjera', 
              disabled: false 
            }
          ],
        d_sexo: [
                  { value: 1, text: 'Femenino' },
                  { value: 2, text: 'Masculino', 
                    disabled: false 
                  }
                ],                        
        d_edo_civil: [
                  { value: 1, text: 'Soltero' },
                  { value: 2, text: 'Casado' },
                  { value: 3, text: 'Concubino' },
                  { value: 4, text: 'Divorciado' },
                  { value: 5, text: 'Viudo', 
                    disabled: false 
                  }
                ],              
        d_mano_dominante: [
                  { value: 1, text: 'Diestro' },
                  { value: 2, text: 'Zurdo' },
                  { value: 3, text: 'Ambidiestro', 
                    disabled: false 
                  }
                ],              
        d_nivel_educativo: [
                  { value: 1, text: 'Iletrado' },
                  { value: 2, text: 'Básica completa' },
                  { value: 3, text: 'Básica incompleta' },
                  { value: 4, text: 'Media Completa' },
                  { value: 5, text: 'Media Incompleta' },
                  { value: 6, text: 'TSU completa' },
                  { value: 7, text: 'TSU incompleta ' },
                  { value: 8, text: 'Universitaria completa' },
                  { value: 9, text: 'Universitaria incompleta ' },
                  { value: 10, text: 'Postgrado', 
                    disabled: false 
                  }
                ],              

    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      search () {
        // Items have already been loaded
        if (this.itemsRepresentante.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        this.$store.dispatch(MUESTRA_PERSONA, {}).then(response => {
            this.codRepresentante = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        }).finally(() => (this.isLoading = false))      

      },          
  },     
  computed: {
    ...mapGetters(['fields_persona']),
    fecha_reg_max() {
       return new Date().toISOString().slice(0,10);

    },  
  },
  props: {
    send_persona: Object
  },
  components: {

  },    
  methods: {
    calcular_edad() {
        const today = new Date();
        this.fields_persona.group_datos_basico.bo_posee_cedula.bo_posee_cedula = false
        this.edad = { cantidad: 0, medida: 'año(s)'}
        var fecha2 = moment(today);
        var fecha1 = moment(this.fields_persona.group_datos_basico.fe_nacimiento.fe_nacimiento);      
        
        this.edad.cantidad = fecha2.diff(fecha1, 'years')

        if (this.edad.cantidad < 1) {
          this.edad.cantidad = fecha2.diff(fecha1, 'months')
          this.isHiddenRepresentante = false
          this.isHiddenTieneCedula = true
          if (this.edad.cantidad < 1) {
            this.edad.cantidad = fecha2.diff(fecha1, 'days')
            this.edad.medida = ' día(s)'
          } else {
            this.edad.medida =  ' mes (es)'
          } 
        } else {
            this.edad.medida = ' año(s)'
            this.isHiddenRepresentante = false
            if (this.edad.cantidad > 8 && this.edad.cantidad < 18) {
              this.isHiddenTieneCedula = false
            } else {
              if (this.edad.cantidad >= 18) {
                this.isHiddenRepresentante = true
                this.isHiddenTieneCedula = true
              } else {
                if (this.edad.cantidad < 9) {
                  this.isHiddenTieneCedula = true
                } else {
                  this.isHiddenTieneCedula = false
                }
              }
            }
        } 
        this.fields_persona.group_datos_basico.nu_edad.caracteristicas.label =  'Edad (' + this.edad.medida + ') *' 
        this.fields_persona.group_datos_basico.nu_edad.nu_edad = this.edad.cantidad
        //return edad
    },
    verificar_edad() {
      console.log("fecha nacimiento: ", this.fields_persona.group_datos_basico.bo_es_menor.bo_es_menor)
      switch(this.fields_persona.group_datos_basico.bo_es_menor.bo_es_menor) {
        case "true":
          this.isHiddenTieneCedula = false
          break;
        default:
          this.isHiddenTieneCedula = true
          // code block
      }    
    },    
    verificar_bo_posee_cedula() {
      console.log("this.isHiddenTieneCedula: ", this.fields_persona.group_datos_basico.bo_posee_cedula.bo_posee_cedula)
      switch(this.fields_persona.group_datos_basico.bo_posee_cedula.bo_posee_cedula) {
        case "true":
          this.isHiddenRepresentante = true
          break;
        default:
          this.isHiddenRepresentante = false
          // code block
      }    
    }, 
    itemsRepresentante () {
      return this.codRepresentante.map(entry => {
        const Description = entry.tx_nombre_completo.length > this.descriptionLimit
          ? entry.tx_nombre_completo.slice(0, this.descriptionLimit) + '...'
          : entry.tx_nombre_completo

        return Object.assign({}, entry, { Description })
      })
    },       
    async guardar () {
                        const isValid = await this.$refs.datos_basicos.validate()
                        if (!isValid) {
                            return true
                        }
                        
                        
                        this.$emit("guardar_persona_datos_basicos", JSON.stringify(this.fields_persona.group_datos_basico));

                        


    },              
    async cancelar () {
        this.$notify({
        group: 'error',
        type: 'Info',
        title: 'Mensaje importante',
        classes: 'w-full text-lg',
        text: 'Operación cancelada!'
        });      
    //this.loading4 = true 
        this.$emit("guardar_enfermedad_actual", JSON.stringify(this.fields_historia_clinica));

    this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

    }, 

  },
  
  mounted() {
    //this.calcular_edad()
    //console.log('Edad: ', this.fields_persona.group_datos_basico.fe_nacimiento.fe_nacimiento)
  },
    
}
</script>