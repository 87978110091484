<template>
<div >
    <div class="p-2 border-2  rounded-md">
        <div>
            <UsuarioEspecialidadesDatosHeader :titulo="titulo"  />        
        </div>
      <div>
          <UsuarioEspecialidadesDatosBasicos  @guardar_especialidades="return_especialidades" :send_especialidades="send_especialidades">
          </UsuarioEspecialidadesDatosBasicos>
      </div>       
    </div> 

</div>
</template>
<script>
import UsuarioEspecialidadesDatosHeader from "@/components/comunes/HeaderModulo.vue";
import UsuarioEspecialidadesDatosBasicos from "@/components/seguridad/UsuarioEspecialidadesDatosBasicos.vue";
import { mapGetters } from 'vuex'

export default {
    name: 'UsuarioEspecialidadesRegistrar',
    data() {
        return {
 //           titulo: "Programar "
        }
    },
    computed: {
        ...mapGetters(['fields_consulta_medica']),
    },
    props: {
        send_especialidades: Object,
        titulo: String
    },
    components: {
      UsuarioEspecialidadesDatosBasicos,
      UsuarioEspecialidadesDatosHeader
    },
    methods: {        
        return_especialidades(msg) {
        //this.buscarPersona('params')
        var guardar_consulta_medica = JSON.parse(msg);
        this.select_paciente = JSON.parse(msg);
        console.log("ConsultaMedicaRegistrar: " , this.select_paciente, guardar_consulta_medica);
        //console.log("ConsultaMedicaRegistrar: " , this.select_paciente, guardar_consulta_medica);

        
        if (guardar_consulta_medica.btn_guardar == 'true') {
            //this.searchConsultaMedica({})
            //this.fields.paciente = this.select_paciente.nu_id_representante
            console.log("Guardar ConsultaMedicaRegistrar: " + guardar_consulta_medica.btn_guardar);

        } else {
            console.log("Cancelar: " + guardar_consulta_medica.btn_guardar);
        }
        this.$emit("guardar_consulta_medica", JSON.stringify(guardar_consulta_medica));
        this.dialogConsultaMedica = false
        //this.send_paciente = msg;
        },         
    },
    mounted() {

        
        //for (const property in this.send_consulta_medica_temp.paciente) {
        //    this.send_consulta_medica_temp.paciente[property][property] = this.send_consulta_medica.paciente[property]
        //}        
        //this.send_consulta_medica_temp.paciente = this.fields_consulta_medica 
        //this.send_consulta_medica_temp.paciente.nu_id_paciente.nu_id_paciente = this.send_consulta_medica.nu_id_paciente
        //  console.log('this.send_consulta_medica: ', this.send_consulta_medica.paciente.tx_nombre_completo )
        //this.titulo += " " + this.send_consulta_medica.paciente.tx_nombre_completo;
    },         

  
}
</script>