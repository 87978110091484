<template>
<div >
    <div class="">
        <div v-if="titulo">
            <PersonaHeader :titulo="titulo "  />        

        </div>
      <div>
         <v-stepper v-model="e1">
            <v-stepper-header>
                  <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>
                  <v-stepper-step
                    :complete="e1 > 3"
                    step="3"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="4">
                  </v-stepper-step>
            </v-stepper-header>
            <div class="flex justify-center mt-1 text-lg font-bold"> 
                {{vista[e1 - 1].descripcion }}
            </div>
            <div class="flex justify-center mt-1 text-xs font-bold"> 
                Los campos con el "*" son Obligatorios
            </div>
      <div v-if="send_persona.operacion === 'EDITAR'" class="flex justify-center items-center" @click="fields_persona.isEditing = !fields_persona.isEditing ">
          <div  v-if="fields_persona.isEditing === true" class="cursor-pointer py-2 px-6 mr-1 w-12 flex justify-center items-center bg-skin-button-muted">
              <div class="pr-2">
                  <v-icon
                  color="white"
                  >                                
                  {{ icons.mdiStopCircleOutline }}
                  </v-icon>
              </div>

              <div class="text-white">
                Cancelar edición
              </div>

          </div>  
          <div v-else   class="cursor-pointer py-2 px-6 mr-1 w-12 flex justify-center items-center bg-skin-button-accent">
              <div class="pr-2">
                  <v-icon
                  color="white"
                  >                                
                  {{ icons.mdiPencil }}
                  </v-icon>
              </div>

              <div class="text-white">
                Editar
              </div>

          </div>
      </div>            
          <v-stepper-items > 
            <div class="">
              <ValidationObserver ref="datos_basicos">
                  <v-stepper-content step="1">  
                    <PersonaDatosBasicos @guardar_datos_basicos="return_datos_basicos" :send_persona="send_persona"  />
                    <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-between  pt-2 pb-10">
                      <div class="">
                          <div class="flex justify-start">
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>


                      <div class="">
                          <div class="flex justify-start">
                                <button @click="onSubmit_datos_basicos(1)" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Siguiente
                                </button>                          
                                <button @click="onSubmit_datos_basicos(1)" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowRightBoldOutline }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>
                    </div>
                    <!-- Fin Boton de Navegacion -->                    
                  </v-stepper-content>
              </ValidationObserver>
                <ValidationObserver ref="datos_ubi_geo">
                  <v-stepper-content step="2">
                    <PersonaDatosUbigeo @guardar_datos_basicos="return_datos_basicos" :send_persona="send_persona"  />
                    <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-between  pt-2 pb-10">
                      <div class="">
                          <div class="flex justify-start">
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          </div>    


                      </div>


                      <div class="">
                          <div class="flex justify-start">
                                <button @click="anterior(2)" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Anterior
                                </button>                          
                                <button @click="anterior(2)" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowLeftBoldOutline }}
                                                </v-icon>
                                </button>                          

                                <button @click="onSubmit_ubi_geo(2)" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Siguiente
                                </button>                          
                                <button @click="onSubmit_ubi_geo(2)" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowRightBoldOutline }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>
                    </div>
                    <!-- Fin Boton de Navegacion -->                    
                  </v-stepper-content>
                </ValidationObserver>
                <ValidationObserver ref="datos_inf_contacto">
                  <v-stepper-content step="3">
                    <PersonaDatosContacto @guardar_datos_basicos="return_datos_basicos" :send_datos_basicos="send_persona"  />
                    <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-between">
                      <div class="">
                          <div class="flex justify-start">
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          </div>    


                      </div>


                      <div class="">
                          <div class="flex justify-start">
                                <button @click="anterior(3)" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Anterior
                                </button>                          
                                <button @click="anterior(3)" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowLeftBoldOutline }}
                                                </v-icon>
                                </button>                          

                                <button @click="onSubmit_inf_contacto(3)" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Siguiente
                                </button>                          
                                <button @click="onSubmit_inf_contacto(3)" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowRightBoldOutline }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>
                    </div>
                    <!-- Fin Boton de Navegacion -->                    

                  </v-stepper-content>
                </ValidationObserver>
                <ValidationObserver ref="datos_redes_sociales">
                  <v-stepper-content step="4">
                    <PersonaDatosRedesSociales @guardar_datos_basicos="return_datos_basicos" :send_datos_basicos="send_persona"  />
                    <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-between">
                      <div class="">
                          <div class="flex justify-start">
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          </div>    


                      </div>


                      <div class="">
                          <div class="flex justify-start">
                                <button @click="anterior(4)" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Anterior
                                </button>                          
                                <button @click="anterior(4)" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiArrowLeftBoldOutline }}
                                                </v-icon>
                                </button>                          

                                <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                  Procesando...
                                </button>                                                        
                                <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                  Guardar
                                </button>                                                        

                                <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                </button>                                                        
                      

                                <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiContentSave }}
                                                </v-icon>
                                </button>                          
                          </div>

                      </div>
                    </div>
                    <!-- Fin Boton de Navegacion -->                    

                  </v-stepper-content>
                </ValidationObserver>


            </div>
          </v-stepper-items> 
        </v-stepper>
      </div>       
    </div> 

</div>
</template>
<script>
import { mapGetters } from 'vuex'

import PersonaHeader from "@/components/comunes/HeaderModulo";
import PersonaDatosBasicos from "@/components/persona/PersonaDatosBasicos.vue";
import PersonaDatosUbigeo from "@/components/persona/PersonaDatosUbigeo";
import PersonaDatosContacto from "@/components/persona/PersonaDatosContacto";
import PersonaDatosRedesSociales from "@/components/persona/PersonaDatosRedesSociales";
const moment = require('moment');


import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus,
  mdiArrowRightBoldOutline,
  mdiArrowLeftBoldOutline,
  mdiStopCircleOutline,
  mdiContentSave,
  mdiLoading

} from '@mdi/js';
import { reactive  } from "@vue/composition-api"

export default {
    name: 'PersonaRegistrar',
    data() {
        return {
            e1: 1,
            isLoading: false,
            mostrar_vista: {
                id: '1',
                descripcion: 'Datos básicos'
                }, 
            vista: [
                {
                id: '1',
                descripcion: 'Datos básicos'
                },
                {
                id: '2',
                descripcion: 'Ubicación Geográfica'
                },
                {
                id: '3',
                descripcion: 'Datos de contacto'
                },
                {
                id: '4',
                descripcion: 'Redes sociales'
                },
                {
                id: '5',
                descripcion: 'LEADS'
                },

            ], 
            selected: {
                json_estado_co: [],
                json_municipio_co: [],
                json_parroquia_co: [],
            },            

 //           titulo: "Programar "
        }
    },
    props: {
        send_persona: Object,
        titulo: String
    },
    components: {
      PersonaDatosBasicos,
      PersonaHeader,
      PersonaDatosUbigeo,
      PersonaDatosContacto,
      PersonaDatosRedesSociales
    },
    computed: {
        ...mapGetters(['fields_persona']),
        notificar() {
        return this.noti;
        },
    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },    
    },    
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus,
            mdiArrowRightBoldOutline,
            mdiArrowLeftBoldOutline,
            mdiStopCircleOutline,
            mdiContentSave,
            mdiLoading
        });  


        return {
        icons
        }
    },
    methods: { 
    calcular_edad() {
        const today = new Date();
        this.fields_persona.bo_posee_cedula.bo_posee_cedula = false
        this.edad = { cantidad: 0, medida: 'año(s)'}
        var fecha2 = moment(today);
        var fecha1 = moment(this.fields_persona.fe_nacimiento.fe_nacimiento);      
        
        this.edad.cantidad = fecha2.diff(fecha1, 'years')

        if (this.edad.cantidad < 1) {
          this.edad.cantidad = fecha2.diff(fecha1, 'months')
          this.isHiddenRepresentante = false
          this.isHiddenTieneCedula = true
          if (this.edad.cantidad < 1) {
            this.edad.cantidad = fecha2.diff(fecha1, 'days')
            this.edad.medida = ' día(s)'
          } else {
            this.edad.medida =  ' mes (es)'
          } 
        } else {
            this.edad.medida = ' año(s)'
            this.isHiddenRepresentante = false
            if (this.edad.cantidad > 8 && this.edad.cantidad < 18) {
              this.isHiddenTieneCedula = false
            } else {
              if (this.edad.cantidad >= 18) {
                this.isHiddenRepresentante = true
                this.isHiddenTieneCedula = true
              } else {
                if (this.edad.cantidad < 9) {
                  this.isHiddenTieneCedula = true
                } else {
                  this.isHiddenTieneCedula = false
                }
              }
            }
        } 
        this.fields_persona.nu_edad.caracteristicas.label =  'Edad (' + this.edad.medida + ') *' 
        this.fields_persona.nu_edad.nu_edad = this.edad.cantidad
        //return edad
    },

        return_datos_basicos(msg) {
          console.log("return_datos_basicos: ", msg)
            //this.buscarPersona('params')
            //var guardar_datos_basicos = JSON.parse(msg);            
            //this.$emit("guardar_persona", JSON.stringify(guardar_datos_basicos));
        },        
        async onSubmit_datos_basicos (step) {
            //const isValid = await this.$refs.datos_basicos.validate()
            const  isValid = true;
            if (isValid) {
                this.mostrar_vista = this.vista[step]
                this.e1 = this.e1 + 1
            }
        },
        async onSubmit_ubi_geo (step) {
            //const isValid = await this.$refs.datos_ubi_geo.validate()
            const  isValid = true;

            if (isValid) {
                this.mostrar_vista = this.vista[step]
                this.e1 = this.e1 + 1
            }
        },
        async onSubmit_inf_contacto (step) {
//        const isValid = await this.$refs.datos_inf_contacto.validate()
            const  isValid = true;

        if (isValid) {
            this.mostrar_vista = this.vista[step]
            this.e1 = this.e1 + 1
        }
        },        
        anterior(step) {
            this.e1 = step - 1
        },
        async cancelar () {
            this.$notify({
            group: 'error',
            type: 'Info',
            title: 'Mensaje importante',
            classes: 'w-full text-lg',
            text: 'Operación cancelada!'
            });      
        //this.loading4 = true 
            console.log("guardar_persona: " , this.fields_persona);
            this.e1 =  1
            
        this.$emit("guardar_persona", JSON.stringify(this.fields_persona));
        this.dialogConsultaMedica = false            

            //this.$emit("guardar_persona", JSON.stringify(this.fields_persona));

            //this.isEditing = !this.isEditing     

        },
        
        async guardar () {
            const isValid = await this.$refs.datos_redes_sociales.validate()
            console.log('Redes Sociales Antes: ', isValid)
            if (!isValid) {
                return true
            }
           // this.isLoading = true
            this.$emit("guardar_persona", JSON.stringify(this.fields_persona));

            
// desde

// hasta
            this.e1 =  1


        }, 
        async limpiar () {
            for (const property in this.fields_persona) {
              if(typeof this.fields_persona[property] === 'object') {
                console.log('property: ', property, this.fields_persona[property]['caracteristicas']['default'])
                if (this.fields_persona[property]['caracteristicas']['default'] != '') {
                  this.fields_persona[property][property] = this.fields_persona[property]['caracteristicas']['default']
                } else {
                  this.fields_persona[property][property] = ''
                }
                  
              }
            }


        },
              
    },
    mounted() {

                  console.log('send_persona Persona Registrar: ', this.send_persona )
          if (this.send_persona.operacion === 'EDITAR') {
              for (var prop in this.fields_persona) {
              if(typeof this.fields_persona[prop] === 'object') {
                    if (this.fields_persona[prop]['caracteristicas']['tipo'] === 'JSON') {
                      this.fields_persona[prop][prop] = JSON.parse(this.send_persona[prop])
                    } else {
                      this.fields_persona[prop][prop] = this.send_persona[prop]
                    }

                  }

              //this.fields_consulta_medica[prop][prop] = this.send_consulta_medica.paciente[prop]
              }
              this.calcular_edad()
          }

/*        if (this.send_persona.operacion === 'REGISTRAR') {
          this.limpiar()
          this.fields_persona.isEditing = true
        } else {
          this.fields_persona.isEditing = false
        }
 */     
    },         

  
}
</script>