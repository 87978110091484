<template>
    <div>
        <ValidationObserver ref="datos_basicos">

                <div  v-if="this.fields_persona.group_datos_ubigeo.bo_posee_cedula == 'false'">
                    <v-btn
                        color="primary"
                        class="w-full"
                        @click="tomar_ubi_geo()"
                    >
                        Tomar los datos del Representante
                    </v-btn>                        
                </div> 
                <validation-provider
                v-slot="{ errors }"
                name="fields_persona.group_datos_ubigeo.json_estado_co.json_estado_co"
                :rules=fields_persona.group_datos_ubigeo.json_estado_co.caracteristicas.required
                >
                    <v-autocomplete
                    v-model="fields_persona.group_datos_ubigeo.json_estado_co.json_estado_co"
                    :items="listaEntidades"
                    :loading="isLoading"
                    :error-messages="errors"
                    hide-no-data
                    hide-selected
                    item-text="tx_desc_entidad"
                    item-value="tx_cod_entidad"
                    :label=fields_persona.group_datos_ubigeo.json_estado_co.caracteristicas.label
                    :placeholder=fields_persona.group_datos_ubigeo.json_estado_co.caracteristicas.placeholder
                    prepend-icon=""
                    return-object
                    outlined
                    @change="searchMunicipio"
                    :disabled=!fields_persona.isEditing                            
                    ></v-autocomplete>
                </validation-provider>

                <validation-provider
                v-slot="{ errors }"
                name="fields_persona.group_datos_ubigeo.json_municipio_co.json_municipio_co"
                :rules=fields_persona.group_datos_ubigeo.json_municipio_co.caracteristicas.required
                >
                    <v-autocomplete
                    v-model="fields_persona.group_datos_ubigeo.json_municipio_co.json_municipio_co"
                    :items="codigosMunicipio"
                    :loading="isLoading"
                    :error-messages="errors"
                    hide-no-data
                    hide-selected
                    item-text="tx_desc_municipio"
                    item-value="tx_cod_municipio"
                    :label=fields_persona.group_datos_ubigeo.json_municipio_co.caracteristicas.label
                    :placeholder=fields_persona.group_datos_ubigeo.json_municipio_co.caracteristicas.placeholder
                    prepend-icon=""
                    return-object
                    outlined
                    @change="searchParroquia"
                    :disabled=!fields_persona.isEditing
                    ></v-autocomplete>
                </validation-provider>
                <validation-provider
                v-slot="{ errors }"
                name="fields_persona.group_datos_ubigeo.json_parroquia_co.json_parroquia_co"
                :rules=fields_persona.group_datos_ubigeo.json_parroquia_co.caracteristicas.required
                >
                    <v-autocomplete
                    v-model="fields_persona.group_datos_ubigeo.json_parroquia_co.json_parroquia_co"
                    :items="codigosParroquia"
                    :loading="isLoading"
                    :error-messages="errors"
                    hide-no-data
                    hide-selected
                    item-text="tx_desc_parroquia"
                    item-value="tx_cod_parroquia"
                    :label=fields_persona.group_datos_ubigeo.json_parroquia_co.caracteristicas.label
                    :placeholder=fields_persona.group_datos_ubigeo.json_parroquia_co.caracteristicas.placeholder
                    prepend-icon=""
                    outlined
                    return-object
                    :disabled=!fields_persona.isEditing
                    ></v-autocomplete>
                </validation-provider>
                <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

                    <validation-provider
                    v-slot="{ errors }"
                    name="fields_persona.group_datos_ubigeo.tx_direccion.tx_direccion"
                    rules="required"
                    >
                        <v-textarea
                            v-model="fields_persona.group_datos_ubigeo.tx_direccion.tx_direccion"
                            auto-grow
                            v-bind="attrs"
                            v-on="on"
                            class="input-group--focused text-lg"
                            :error-messages="errors"
                            :label=fields_persona.group_datos_ubigeo.tx_direccion.caracteristicas.label                
                            outlined
                            :disabled=!fields_persona.isEditing
                        ></v-textarea>                      
                    </validation-provider>   
            </template>
            <span>{{ fields_persona.group_datos_ubigeo.tx_direccion.caracteristicas.tooltip  }} </span>
            </v-tooltip>                
                    <!-- Inicio Boton de Navegacion -->
                <div class="flex justify-end">

                    <div>
                            <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        Cancelar
                            </button>                          
                            <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            <v-icon
                                            color="white"
                                            >                                
                                            {{ icons.mdiStopCircleOutline }}
                                            </v-icon>
                            </button>                          
                        
                    </div>
                    <div>

                            <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                                <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                    <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                    <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                        <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                        <animateTransform
                                            attributeName="transform"
                                            dur="2s"
                                            type="rotate"
                                            from="0 50 48"
                                            to="360 50 52"
                                            repeatCount="indefinite" />
                                        
                                        </circle>
                                    </svg>
                                </div>
                                Procesando...
                            </button>                                                        
                            <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                Guardar
                            </button>                                                        

                            <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                                <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                    <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                    <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                        <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                        <animateTransform
                                            attributeName="transform"
                                            dur="2s"
                                            type="rotate"
                                            from="0 50 48"
                                            to="360 50 52"
                                            repeatCount="indefinite" />
                                        
                                        </circle>
                                    </svg>
                                </div>
                            </button>                                                        
                    

                            <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            <v-icon
                                            color="white"
                                            >                                
                                            {{ icons.mdiContentSave }}
                                            </v-icon>
                            </button>                          
                    </div>
                    </div>
                <!-- Fin Boton de Navegacion -->    
        </ValidationObserver>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {  MUESTRA_ENTIDAD, MUESTRA_MUNICIPIO,MUESTRA_PARROQUIA  } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import { reactive  } from "@vue/composition-api"
//import { UPDATE_CONSULTA_MEDICA_ENFERMEDAD_ACTUAL} from '@/store/actions.type'
//import { SET_ERROR } from '@/store/mutations.type'

import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached,
  mdiStopCircleOutline,
  mdiContentSave
} from '@mdi/js'


    export default {
        name: 'PersonaDatosUbigeo',
        data() {
            return {
                isHiddenRepresentante: true,
                codigosEntidad: [],
                codigosMunicipio: [],
                codigosParroquia: [],
                isLoading: false,
                selected: {
                    json_estado_co: [],
                    json_municipio_co: [],
                    json_parroquia_co: [],
                },                
            }
        },
        setup() {
            const icons = reactive({
                mdiClose,
                mdiAccount,
                mdiAlarmMultiple,
                mdiAlarmPlus,
                mdiPencil,
                mdiCalendar,
                mdiAccountHeart,
                mdiNeedle,
                mdiRadiologyBoxOutline,
                mdiCached,
                mdiDelete,
                mdiStopCircleOutline,
                mdiContentSave
            });  


            return {
            icons
            }
        },  
        props: {
            send_persona: Object
        },
        computed: {
            ...mapGetters(['fields_persona']),
            listaEntidades () {
                return this.codigosEntidad.map(entry => {
                    const Description = entry.tx_desc_entidad.length > this.descriptionLimit
                    ? entry.tx_desc_entidad.slice(0, this.descriptionLimit) + '...'
                    : entry.tx_desc_entidad

                    return Object.assign({}, entry, { Description })
                })
            },             
        },
        methods: {
            searchEntidad(q) {
            this.$store.dispatch(MUESTRA_ENTIDAD, {q: q}).then(response => {
                this.codigosEntidad = response.rest.items
            }, error => {
                this.$store.commit(SET_ERROR, error.statusText)
            })      
            },      
            searchMunicipio(value) {
                this.codigosMunicipio = []
                this.codigosParroquia = []
                this.fields_persona.group_datos_ubigeo.json_municipio_co.json_municipio_co = []
                this.$store.dispatch(MUESTRA_MUNICIPIO, {tx_cod_entidad: value.tx_cod_entidad }).then(response => {
                    this.codigosMunicipio = response.rest.items
                }, error => {
                    this.$store.commit(SET_ERROR, error.statusText)
                })      
            },        
            searchParroquia(value) {
                console.log('Parroquia: ', value)
                this.$store.dispatch(MUESTRA_PARROQUIA, {tx_cod_entidad: value.tx_cod_entidad, tx_cod_municipio: value.tx_cod_municipio  }).then(response => {
                    this.codigosParroquia = response.rest.items
                }, error => {
                    this.$store.commit(SET_ERROR, error.statusText)
                })      
            },
            tomar_ubi_geo() {
                this.fields_persona.group_datos_ubigeo.json_estado_co = this.fields_persona.group_datos_ubigeo.select_representante.json_cod_entidad
                this.searchMunicipio(this.fields_persona.group_datos_ubigeo.select_representante.json_cod_entidad)
                this.fields_persona.group_datos_ubigeo.json_municipio_co = this.fields_persona.group_datos_ubigeo.select_representante.json_cod_municipio
                this.searchParroquia(this.fields_persona.group_datos_ubigeo.select_representante.json_cod_municipio)
                this.fields_persona.group_datos_ubigeo.json_parroquia_co = this.fields_persona.group_datos_ubigeo.select_representante.json_cod_parroquia
                this.fields_persona.group_datos_ubigeo.tx_direccion = this.fields_persona.group_datos_ubigeo.select_representante.tx_direccion
            },
            async guardar () {
                const isValid = await this.$refs.datos_basicos.validate()
                if (!isValid) {
                    return true
                }                                                                
                this.$emit("guardar_ubigeo", JSON.stringify(this.fields_persona.group_datos_ubigeo));
            },              
            async cancelar () {
                this.$notify({
                group: 'error',
                type: 'Info',
                title: 'Mensaje importante',
                classes: 'w-full text-lg',
                text: 'Operación cancelada!'
                });      
            //this.loading4 = true 
                this.$emit("guardar_enfermedad_actual", JSON.stringify(this.fields_historia_clinica));

            this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

            }, 

        },
        mounted() {

            this.searchEntidad()
            if (this.send_persona.operacion === 'EDITAR') {
                for (var prop in this.fields_persona.group_datos_ubigeo) {
                    this.fields_persona.group_datos_ubigeo[prop][prop] = this.send_persona.institucion[prop]
                }
                this.fields_persona.group_datos_ubigeo.json_estado_co.json_estado_co = JSON.parse(this.send_persona.institucion.json_estado_co)
                this.searchMunicipio(this.fields_persona.group_datos_ubigeo.json_estado_co.json_estado_co)
                this.fields_persona.group_datos_ubigeo.json_municipio_co.json_municipio_co = JSON.parse(this.send_persona.institucion.json_municipio_co)
                this.searchParroquia(this.fields_persona.group_datos_ubigeo.json_municipio_co.json_municipio_co)
                this.fields_persona.group_datos_ubigeo.json_parroquia_co.json_parroquia_co = JSON.parse(this.send_persona.institucion.json_parroquia_co)

                //this.isHiddenTieneCedula = this.fields_persona.group_datos_ubigeo.bo_posee_cedula.bo_posee_cedula
            }
        }
        
        
    }
</script>
