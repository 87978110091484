<template>
  <div class="">
        <div class="">
            <div>
                <v-data-table
                  :headers="headers"
                  :items="fields_centro_medico_usuario.json_centro_medico.json_centro_medico"
                  sort-by="calories"
                  class="elevation-1 mb-10"

                >
                
                  <template v-slot:item.json_especialidades="{ item }">
                      <div v-for="(item_esp, index) in item.json_especialidades" :key="index">

                      {{ item_esp.tx_especialidad }}
                      </div>
                  </template>     
                  <template v-slot:item.json_centro_medico="{ item }">
                      {{ item.json_centro_medico.tx_razon_social }}
                  </template>     
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Agregar Centro Médico
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                      >

                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="cm_esp.json_centro_medico"
                                          :rules=fields_centro_medico_usuario.json_centro_medico.caracteristicas.required
                                        >
                                          <v-autocomplete
                                            v-model='cm_esp.json_centro_medico'
                                            :items="centrosmedicos"
                                            :loading="isLoading"
                                            :error-messages="errors"
                                            hide-no-data
                                            hide-selected
                                            item-text="tx_razon_social"
                                            item-value="nu_id_centro_medico"
                                            :label=fields_centro_medico_usuario.json_centro_medico.caracteristicas.label
                                            :placeholder=fields_centro_medico_usuario.json_centro_medico.caracteristicas.placeholder
                                            prepend-icon=""
                                            return-object
                                          ></v-autocomplete>
                                      </validation-provider>                    
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >                  
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="cm_esp.json_especialidades"
                                          :rules=fields_centro_medico_usuario.json_especialidades.caracteristicas.required
                                        >
                                        <v-select
                                          v-model='cm_esp.json_especialidades'
                                          :placeholder=fields_centro_medico_usuario.json_especialidades.caracteristicas.placeholder
                                            :error-messages="errors"
                                          :items="fields_especialidad_usuario.json_especialidad.json_especialidad"
                                          item-text="tx_especialidad"
                                          item-value="nu_id_especialidad"
                                          :label=fields_centro_medico_usuario.json_especialidades.caracteristicas.label
                                          persistent-hint
                                          multiple
                                          return-object
                                        ></v-select>       
                                      </validation-provider>                    
                                      </v-col>

                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Guardar</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  READ_CENTRO_MEDICO } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'UsuarioCentroMedicoDatosBasicos',
  data () {
    return {
        centrosmedicos: [],
        fields_centro_medico_usuario_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          json_centro_medico: '',
          json_especialidades: '',
        },  
        defaultItem: {
          json_centro_medico: [],
          json_especialidades: [],
          caracteristicas: {
            nombre: 'json_centro_medico',
            label: 'Centro médico *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione el centro médico',
            tooltip: 'Centro médico',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   
        cm_esp: {
          json_centro_medico: '',
          json_especialidades: '',
          caracteristicas: {
            nombre: 'json_centro_medico',
            label: 'Centro médico *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione el centro médico',
            tooltip: 'Centro médico',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   

        headers: [
            {
              text: 'Centro médico',
              align: 'start',
              sortable: false,
              value: 'json_centro_medico',
            },
            { text: 'Especialidades', value: 'json_especialidades' },        
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_centro_medico_usuario','fields_especialidad_usuario']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  props: {
    send_centro_medico: Object
  },
  components: {

  },    
  methods: {
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.cm_esp.json_centro_medico = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.cm_esp = Object.assign({}, this.fields_centro_medico_usuario.json_centro_medico.json_centro_medico)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.fields_centro_medico_usuario.json_centro_medico.json_centro_medico[this.editedIndex], this.cm_esp)
        } else {
          console.log('this.editedItem: ', this.fields_centro_medico_usuario.json_centro_medico.json_centro_medico)
          /*var aa = {
            json_centro_medico: this.cm_esp.json_centro_medico,
            json_especialidades: this.cm_esp.json_especialidades
          }
          */
          //this.fields_centro_medico_usuario.json_centro_medico.json_especialidades = this.cm_esp.json_especialidades
          this.fields_centro_medico_usuario.json_centro_medico.json_centro_medico.push({
            json_centro_medico: this.cm_esp.json_centro_medico,
            json_especialidades: this.cm_esp.json_especialidades
          })
          //this.fields_centro_medico_usuario.json_centro_medico.json_centro_medico.push(this.cm_esp.json_centro_medico)
        }
        this.close()
      },    
      initialize () {
        this.cm_esp = this.defaultItem
      },

      editItem (item) {
        this.editedIndex = this.fields_centro_medico_usuario.json_centro_medico.json_centro_medico.indexOf(item)
        this.cm_esp = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        console.log('deleteItem: ', item.json_centro_medico.nu_id_centro_medico)
        this.editedIndex = this.fields_centro_medico_usuario.json_centro_medico.json_centro_medico.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_centro_medico_usuario.json_centro_medico.json_centro_medico.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    searchCentroMedico() {
      this.$store.dispatch(READ_CENTRO_MEDICO, {}).then(response => {
          this.centrosmedicos = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },
    remove (item) {
      var index = this.fields_centro_medico_usuario.json_centro_medico.json_centro_medico.findIndex(i => i.nu_id_centro_medico === item.nu_id_centro_medico);
      if (index >= 0) this.fields_centro_medico_usuario.json_centro_medico.json_centro_medico.splice(index, 1)
    },    

  },
  
  mounted() {
    this.searchCentroMedico();
  },
    
}
</script>